<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Bild hinzufügen</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" class="ion-padding" v-if="finishedLoading && !isUploading">
      <ion-card>
        <ion-card-header>
          <h2>Lade ein Bild hoch</h2>
        </ion-card-header>
        <ion-card-content>
          <div>Dein Inserat benötigt ein Bild damit es Online gehen kann.</div>
          <div style="text-align: center;" v-if="!showUploadButton">
            <ion-icon :icon="cameraIcon" style="width: 150px; height: 150px;"></ion-icon>
          </div>
          <div class="ion-padding">
            <img :src="imageUrl ? imageUrl : null" />
          </div>
          <ion-button @click="takePicture()" v-if="!showUploadButton" style="width: 100%;">Bild auswählen</ion-button>
          <ion-button @click="takePicture()" v-if="showUploadButton" style="width: 100%;">Anderes Bild auswählen</ion-button>
          <ion-button class="topMargins" @click="addLater()" v-if="!showUploadButton" expand="block" style="width: 100%;" color="warning">Später hinzufügen</ion-button>
          <ion-button class="topMargins" @click="uploadImage()" v-if="showUploadButton" expand="block" style="width: 100%;" color="secondary">Hochladen</ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>

    <ion-content v-if="!finishedLoading || isUploading" class="spinnerCenter">
      <ion-spinner name="dots"></ion-spinner>
    </ion-content>

    <ion-content :fullscreen="true" v-if="finishedUploading && !errorWhileUploading">
      <div class="container">
        <strong class="capitalize">Erfolgreich</strong>
        <p>Dein Bild wurde dem Inserat hinzugefügt</p>
        <ion-button size="large" class="topMargins sideMargins" :href="pathToInseratEdit" expand="block" color="primary">Fertig</ion-button>
      </div>
    </ion-content>

    <ion-content :fullscreen="true" v-if="finishedUploading && errorWhileUploading">
      <div class="container">
        <strong class="capitalize">Fehler</strong>
        <p>Dein Bild konnte nicht hinzugefügt werden</p>
        <ion-button size="large" class="topMargins sideMargins" @click="addLater()" expand="block" color="primary">Später versuchen</ion-button>
      </div>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButton, toastController, IonSpinner, IonCardHeader, IonCardContent, IonCard, IonIcon } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';

import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
const { Camera } = Plugins;

import { camera } from 'ionicons/icons';


export default defineComponent({
  name: 'InseratAddImage',
  data () {
    return {
      finishedLoading: true,
      isUploading: false,
      errorWhileUploading: false,
      finishedUploading: false,
      errorWhileLoading: false,
      file: null,
      fileUrl: '',
      inserat: null,
      pathToInseratEdit: null,
      cameraIcon: camera
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonSpinner,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonIcon
  },
  created() {
    localStorage.addedImageToInserat = false;

    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){
      // ready to create inserat
    } else {
      // push to main
      this.$router.push({ path: '/login' });
    }

    // check user permission
    if (!localStorage.userRoles.includes('ROLE_STAR')){
      this.$router.push({ path: '/login' });
    }

    // check if route param is available
    if (!this.$route.params.id){
      this.$router.push({ path: '/profile/inserats' });
    }

    // get inserat data
    axios.get(process.env.VUE_APP_API_URL + '/inserats/' + this.$route.params.id, {
      headers: {
        'Authorization': `Bearer ` + localStorage.userToken
      }
    })
        .then(response => {
          this.inserat = response.data;
          this.pathToInseratEdit = "/inserat/" + this.inserat.uuid + "/edit";
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });


  },
  methods: {
    done(){
        this.$router.push({ path: '/inserat/' + this.inserat.uuid + "/edit"});
    },
    addLater(){
        this.$router.push({ path: '/profile/inserats'});
    },
    uploadImage() {

      if (this.imageUrl) {

        this.isUploading = true;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const currentView = this;

        // convert dataUrl from Image to File
        const timestamp = Math.floor(Date.now() / 1000);
        const fileSuffix = ".jpeg";
        const imageFile = this.dataURLtoFile(this.imageUrl, '' + timestamp + fileSuffix);

        const inseratId = '/inserats/' + this.inserat.uuid;
        const formData = new FormData();
        formData.append("file", imageFile);
        formData.append("inserat", inseratId);
        axios.post(process.env.VUE_APP_API_URL + "/inserat_images", formData, {
          headers: {
            'Authorization': `Bearer ` + localStorage.userToken
          }
        })
            .then(function (result) {
              console.log(result);
            }).catch(function (error) {
          this.errorWhileUploading = true;
        }).finally(function () {
          currentView.isUploading = false;
          currentView.finishedUploading = true;
        });

      }

    },
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    },
    dataURLtoFile(dataurl, filename) {

      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type:mime});
    }
  },
  setup() {
    const imageUrl = ref<string | null>();
    const showUploadButton = ref<boolean | false>();
    const takePicture = async () => {
      // Otherwise, make the call:
      try {
        const image = await Camera.getPhoto({
          quality: 100,
          allowEditing: true,
          resultType: CameraResultType.DataUrl,
          source: CameraSource.Prompt,
        });
        // image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
        imageUrl.value = image.dataUrl;

        showUploadButton.value = true;

      } catch (e) {
        console.log("error", e);
      }
    };
    return {
      takePicture,
      imageUrl,
      showUploadButton
    };
  },
});

</script>

<style scoped>

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.sideMargins {
  margin-left: 25px;
  margin-right: 25px;
}

.topMargins {
  margin-top: 10%;
}

.imageCircle {
  border: 2px solid #1dc0c6;
}

.imageCircleDenied {
  border: 2px solid #c61d53;
}

.imageCircleAccepted {
  border: 2px solid #1dc620;
}

.rounded-image {
  border-radius: 50%;
}

.spinnerCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.upload-btn-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  width: 100%;
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

</style>