
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButton, toastController, IonSpinner, IonCardHeader, IonCardContent, IonCard, IonIcon } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';

import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
const { Camera } = Plugins;

import { camera } from 'ionicons/icons';


export default defineComponent({
  name: 'InseratAddImage',
  data () {
    return {
      finishedLoading: true,
      isUploading: false,
      errorWhileUploading: false,
      finishedUploading: false,
      errorWhileLoading: false,
      file: null,
      fileUrl: '',
      inserat: null,
      pathToInseratEdit: null,
      cameraIcon: camera
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonSpinner,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonIcon
  },
  created() {
    localStorage.addedImageToInserat = false;

    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){
      // ready to create inserat
    } else {
      // push to main
      this.$router.push({ path: '/login' });
    }

    // check user permission
    if (!localStorage.userRoles.includes('ROLE_STAR')){
      this.$router.push({ path: '/login' });
    }

    // check if route param is available
    if (!this.$route.params.id){
      this.$router.push({ path: '/profile/inserats' });
    }

    // get inserat data
    axios.get(process.env.VUE_APP_API_URL + '/inserats/' + this.$route.params.id, {
      headers: {
        'Authorization': `Bearer ` + localStorage.userToken
      }
    })
        .then(response => {
          this.inserat = response.data;
          this.pathToInseratEdit = "/inserat/" + this.inserat.uuid + "/edit";
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });


  },
  methods: {
    done(){
        this.$router.push({ path: '/inserat/' + this.inserat.uuid + "/edit"});
    },
    addLater(){
        this.$router.push({ path: '/profile/inserats'});
    },
    uploadImage() {

      if (this.imageUrl) {

        this.isUploading = true;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const currentView = this;

        // convert dataUrl from Image to File
        const timestamp = Math.floor(Date.now() / 1000);
        const fileSuffix = ".jpeg";
        const imageFile = this.dataURLtoFile(this.imageUrl, '' + timestamp + fileSuffix);

        const inseratId = '/inserats/' + this.inserat.uuid;
        const formData = new FormData();
        formData.append("file", imageFile);
        formData.append("inserat", inseratId);
        axios.post(process.env.VUE_APP_API_URL + "/inserat_images", formData, {
          headers: {
            'Authorization': `Bearer ` + localStorage.userToken
          }
        })
            .then(function (result) {
              console.log(result);
            }).catch(function (error) {
          this.errorWhileUploading = true;
        }).finally(function () {
          currentView.isUploading = false;
          currentView.finishedUploading = true;
        });

      }

    },
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    },
    dataURLtoFile(dataurl, filename) {

      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type:mime});
    }
  },
  setup() {
    const imageUrl = ref<string | null>();
    const showUploadButton = ref<boolean | false>();
    const takePicture = async () => {
      // Otherwise, make the call:
      try {
        const image = await Camera.getPhoto({
          quality: 100,
          allowEditing: true,
          resultType: CameraResultType.DataUrl,
          source: CameraSource.Prompt,
        });
        // image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
        imageUrl.value = image.dataUrl;

        showUploadButton.value = true;

      } catch (e) {
        console.log("error", e);
      }
    };
    return {
      takePicture,
      imageUrl,
      showUploadButton
    };
  },
});

